import type { EntryCollection } from 'contentful';

import React, { ReactElement } from 'react';
import cmsClient from '../../lib/cms/client';
import {
  BrandedSiteProps,
  toSlug,
  getServerSidePropsWithErrors,
  getContentfulPageData,
  cacheDirective,
  getContentfulSiteData,
  isInvalid,
  withSiteData,
  buildFullUrl,
} from '../../lib/routing';
import {
  IMetaLocationsSharedDataFields,
  IPageDoctor,
  IPageLocationFields,
  IProductVariant,
  IProductVariantFields,
} from 'types/contentful';
import { buildComponent } from 'lib/cms/links';
import { LOCATION_SELECT_FIELDS } from 'lib/consts';
import {
  DynamicComponentTypes,
  PreparedComponentObject,
  ProductVariantFieldsWithBrand,
  translateServerSideComponent,
} from 'components';
import { getReviewAverage, getReviewsWithComments, reviewFragmentToRequest } from 'lib/reviewinc';
import {
  generateSSCookiesAndHeaders,
  SearchResultWithVariants,
  searchUrl,
  search,
} from 'lib/searchspring';
import LocationDetailsTemplate2025, {
  LocationDetailsTemplate2025Props,
} from '../../components/UI/LocationTemplate/LocationDetails/Template2025';
import { ServerResponse, IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import DefaultLocationDetailsTemplate, {
  DefaultLocationDetailsTemplateProps,
} from '../../components/UI/LocationTemplate/LocationDetails/Default';
import { removeIfUndefined } from 'lib/util';
import reviewIncClient from '../../lib/reviewinc/client';
import { buildProductUrl } from 'lib/product';

interface VariantObject {
  variants: Record<string, { color?: string }>;
}

export function getColor(obj: VariantObject): string | undefined {
  if (!obj.variants || typeof obj.variants !== 'object') return undefined;

  const firstVariantKey = Object.keys(obj.variants)[0];
  return obj.variants[firstVariantKey]?.color?.toLowerCase();
}

export default function Locations<K extends DynamicComponentTypes, CP>(
  props: LocationDetailsTemplate2025Props | DefaultLocationDetailsTemplateProps<K, CP>
): ReactElement {
  // Check if props is 2025 template format
  if (props.template === '2025') {
    return <LocationDetailsTemplate2025 {...props} />;
  }

  return <DefaultLocationDetailsTemplate {...props} />;
}

export const getServerSideProps = getServerSidePropsWithErrors<
  LocationDetailsTemplate2025Props | DefaultLocationDetailsTemplateProps
>(async ({ resolvedUrl, params, res, req, preview, query }) => {
  const client = cmsClient(preview);
  const slug = toSlug(params?.slug);
  const host = req.headers.host;
  const queryOptions = {
    'fields.site.sys.contentType.sys.id': 'site',
    'fields.site.fields.id': host,
    'fields.slug': slug,
    content_type: 'pageLocation',
    include: 4,
  };

  const siteData = await getContentfulSiteData({ res, req, preview, resolvedUrl });

  if (isInvalid(siteData)) {
    return siteData;
  }

  const contentfulData = await getContentfulPageData<IPageLocationFields>({
    res,
    req,
    preview,
    queryOptions,
    redirectInsteadofNotFound: '/locations',
  });

  if (isInvalid(contentfulData)) {
    return withSiteData(contentfulData, siteData);
  }

  const sharedLocationData: EntryCollection<IMetaLocationsSharedDataFields> = await client.getEntries(
    {
      'fields.site.sys.contentType.sys.id': 'site',
      'fields.site.fields.id': host,
      content_type: 'metaLocationsSharedData',
      include: 4,
    }
  );

  const sharedLocationDataFields = sharedLocationData.items[0]?.fields || {};

  sharedLocationDataFields.frames?.forEach((doc: IProductVariant) => {
    delete doc.fields.locationIds;
  });

  if (sharedLocationDataFields.layoutStyle === '2025 Style') {
    return get2025TemplateData({
      siteData,
      contentfulData,
      preview: preview || false,
      query,
      req,
      res,
      resolvedUrl,
      slug: slug || '',
      host: host || '',
      sharedLocationDataFields,
    });
  }

  return await getDefaultTemplateData({
    contentfulData,
    query,
    siteData,
    preview: preview || false,
    res,
    req,
    resolvedUrl,
    slug: slug || '',
    host: host || '',
    sharedLocationDataFields,
  });
});

type GetDefaultTemplateDataParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentfulData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any;
  siteData: BrandedSiteProps;
  preview: boolean;
  res: ServerResponse;
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
  resolvedUrl: string;
  slug: string;
  host: string;
  sharedLocationDataFields: IMetaLocationsSharedDataFields;
};

async function getDefaultTemplateData({
  contentfulData,
  host,
  preview,
  res,
  slug,
  siteData,
  sharedLocationDataFields,
}: GetDefaultTemplateDataParams): Promise<{ props: DefaultLocationDetailsTemplateProps }> {
  const client = cmsClient(preview);
  const page = contentfulData.item;
  const content = page.fields;

  const coordinates = `${content.map.lat},${content.map.lon}`;

  const nearbyPages: EntryCollection<IPageLocationFields> = await client.getEntries({
    'fields.site.sys.contentType.sys.id': 'site',
    'fields.site.fields.id': host,
    'fields.map[within]': coordinates + ',500', // 80km = 50 mi
    'fields.map[near]': coordinates,
    'sys.id[ne]': page.sys.id,
    select: LOCATION_SELECT_FIELDS,
    limit: 3,
    content_type: 'pageLocation',
  });

  // remove circular depedency
  content.doctors?.forEach((doc: IPageDoctor) => {
    delete doc.fields.primaryLocation;
  });

  const marketingMaterials:
    | PreparedComponentObject<DynamicComponentTypes, unknown>[]
    | null = content.marketingMaterials
    ? await Promise.all(
        content.marketingMaterials
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((item: any) => buildComponent(item.sys.contentType.sys.id, item.fields))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((item: any) => translateServerSideComponent(item, !!preview))
      )
    : null;

  // const reviewAvg = null;
  const reviewAvg =
    content.reviewsIds &&
    (await getReviewAverage(reviewIncClient, reviewFragmentToRequest(content.reviewsIds)));

  // const reviews = null;
  const reviews =
    content.reviewsIds &&
    (await getReviewsWithComments(reviewIncClient, reviewFragmentToRequest(content.reviewsIds)));

  res.setHeader('Cache-Control', cacheDirective(preview));

  return {
    props: {
      template: 'default',
      locationSysId: page.sys.id,
      preview: preview ? true : false,
      title: `${content.name} Eye Doctors & Eye Care Providers`,
      siteData,
      content,
      marketingMaterials,
      nearbyLocations: nearbyPages.items,
      currentSlug: slug || '',
      sharedLocationData: sharedLocationDataFields,
      ...removeIfUndefined({ reviewAvg, reviews }),
      pageDates: {
        datePublished: contentfulData.item.sys.createdAt,
        dateModified: contentfulData.item.sys.updatedAt,
      },
    },
  };
}

type Get2025TemplateDataParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentfulData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any;
  siteData: BrandedSiteProps;
  preview: boolean;
  res: ServerResponse;
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
  resolvedUrl: string;
  slug: string;
  host: string;
  sharedLocationDataFields: IMetaLocationsSharedDataFields;
};

async function get2025TemplateData({
  contentfulData,
  siteData,
  preview,
  query,
  req,
  res,
  resolvedUrl,
  slug,
  host,
  sharedLocationDataFields,
}: Get2025TemplateDataParams): Promise<{ props: LocationDetailsTemplate2025Props }> {
  const client = cmsClient(preview);
  const page = contentfulData.item;
  const content = page.fields;

  // remove circular depedency
  content.doctors?.forEach((doc: IPageDoctor) => {
    delete doc.fields.primaryLocation;
  });

  const searchspringSiteId = siteData?.ecommIds?.fields?.searchSpringId || '';
  query.siteId = searchspringSiteId;
  const skuFilter: string[] = [];
  const productFields: IProductVariantFields[] =
    sharedLocationDataFields.frames?.map((entry: IProductVariant) => {
      const fields = { ...entry.fields }; // Create a copy of fields
      skuFilter.push(`sku=${fields.sku}`); // collect filter
      delete fields.locationIds; // Remove locationIds from the copy
      return fields;
    }) ?? [];

  const headers = generateSSCookiesAndHeaders(req, res);
  const searchSummary = await search(
    searchUrl(query, skuFilter, buildFullUrl(req, resolvedUrl)),
    searchspringSiteId,
    headers
  );

  const searchResults: SearchResultWithVariants[] = searchSummary.results;
  const productWithBrand: ProductVariantFieldsWithBrand[] =
    productFields?.map((item: IProductVariantFields) => {
      // Find matching search result by SKU and add name if found
      const newItem: ProductVariantFieldsWithBrand = {
        ...item,
      };
      const matchingResult = searchResults.find((result) => result?.sku.includes(item.sku));
      if (matchingResult) {
        const name = matchingResult.name;
        newItem.brand = matchingResult.brand;
        const uid = matchingResult.uid;
        const color = getColor(matchingResult);

        if (color && uid && name) {
          newItem.url = buildProductUrl(name, color, uid, newItem.sku);
        }
      }

      return newItem;
    }) ?? [];

  const articles = sharedLocationDataFields.articles?.map((x) => x.fields);

  const coordinates = `${content.map.lat},${content.map.lon}`;

  const nearbyPages: EntryCollection<IPageLocationFields> = await client.getEntries({
    'fields.site.sys.contentType.sys.id': 'site',
    'fields.site.fields.id': host,
    'fields.map[within]': coordinates + ',500', // 80km = 50 mi
    'fields.map[near]': coordinates,
    'sys.id[ne]': page.sys.id,
    select: LOCATION_SELECT_FIELDS,
    limit: 3,
    content_type: 'pageLocation',
  });

  res.setHeader('Cache-Control', cacheDirective(preview));

  return {
    props: {
      template: '2025',
      locationSysId: page.sys.id,
      preview: preview ? true : false,
      title: `${content.name} Eye Doctors & Eye Care Providers`,
      siteData,
      content,
      nearbyLocations: nearbyPages.items,
      articles,
      currentSlug: slug || '',
      sharedLocationData: sharedLocationDataFields,
      pageDates: {
        datePublished: contentfulData.item.sys.createdAt,
        dateModified: contentfulData.item.sys.updatedAt,
      },
      productWithBrand,
    },
  };
}

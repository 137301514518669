import { ReactElement } from 'react';
import { Location } from 'pages/locations';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';
import { formatPhoneNumber } from 'lib/util';

type LocationCardProps = {
  location: Location;
  className?: string;
};

export default function LocationDetailsHeader({
  location,
  className = '',
}: LocationCardProps): ReactElement {
  return (
    <div
      className={`flex flex-col justify-center text-center space-y-2 px-16 pt-5 pb-10 ${className}`}
    >
      <h3 className="text-4xl mb-1 font-light leading-tight">
        Your <b>{location.name} </b> Eye Clinic
      </h3>
      <span className="text-2xl text-primary-actual ">
        {location?.address1 + ' '}
        {location?.address2 && <>{location.address2 + ' '}</>}
        {location?.address3 && <>{location.address3 + ' '}</>}
        {location?.city + ', ' + location?.state + ' ' + location?.zipCode}
      </span>
      {location?.phoneNumber ? (
        <a className="block text-primary-actual text-2xl" href={`tel:${location?.phoneNumber}`}>
          {formatPhoneNumber(location.phoneNumber)}
        </a>
      ) : null}
      <div>
        <ScheduleAppointmentCta
          location={location}
          colorScheme="Primary"
          wrapperClasses=""
          classNames="flex align-center justify-center remove-cta-padding mt-2 px-9 mx-auto min-w-cta max-w-300"
        />
      </div>
    </div>
  );
}

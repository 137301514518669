import Layout from 'components/UI/Layout';
import Image from 'components/UI/Image';
import RichText from 'components/UI/Richtext';
import Link from 'components/UI/Link';
import OfficeDetails, { OfficeHours } from 'components/UI/OfficeDetails';

import type { Entry } from 'contentful';

import React, { ReactElement, useState } from 'react';
import { BrandedSiteProps, PageDates } from 'lib/routing';
import {
  IFragmentService,
  IMetaLocationsSharedDataFields,
  IPageLocationFields,
} from 'types/contentful';
import { doctorDisplayName, formatPhoneNumber, numberToString } from 'lib/util';
import Services from 'components/UI/Services';
import AddressBlock from 'components/UI/AddressBlock';
import LocationCallout from 'components/UI/LocationCallout';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';
import {
  buildDynamicComponent,
  DynamicComponentTypes,
  GenericDef,
  PreparedComponentObject,
} from 'components';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import LocationData from 'components/StructuredData/LocationData';
import SharedLocationDataContext from 'context/SharedLocationDataContext';
import Score from 'components/UI/Reviews/Score';
import { Review, ReviewAverage, reviewToTestimoial } from 'lib/reviewinc';
import Testimonial from 'components/Contentful/Testimonials';
import InsuranceProviders from 'components/UI/InsuranceProviders';
import SchemaOrg, { locationId } from 'components/StructuredData/SchemaOrg';
import { reviewSchema } from 'components/UI/Reviews';
import MapComponent from 'components/UI/Map';
import { Location } from 'components/Contentful/Map';

export type DefaultLocationDetailsTemplateProps<
  K extends DynamicComponentTypes = DynamicComponentTypes,
  CP = unknown
> = {
  preview: boolean;
  content: IPageLocationFields;
  template: 'default';
  marketingMaterials: PreparedComponentObject<K, CP>[] | null;
  nearbyLocations: Entry<IPageLocationFields>[];
  sharedLocationData: IMetaLocationsSharedDataFields;
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  locationSysId: string;
  pageDates?: PageDates;
  reviewAvg?: ReviewAverage;
  reviews?: Review[];
};

const LOCATION_DESCRIPTION = [
  `{siteName} {name} is your full-service eye care center in {city}. {numDoctors} on staff, providing routine eye exams, preventative care, and
  treatment. {siteName} {name} provides complete optical solutions, offering state-of-the-art
  lenses, designer frames and even sunglasses. We can also help fit patients into contact
  lenses and refer patients for LASIK surgery.`,
  `With {siteName} {name}, you can see the sights of {city} more clearly.`,
  `Our {city} optometrists are now accepting new patients at our {name} eye care center.`,
];

const numDocsText = (numDocs: number): string => {
  const numDocsString = numberToString(numDocs);
  if (numDocs === 0) return 'We have highly trained optometrists';
  if (numDocs === 1) return 'We have one highly trained optometrist';
  return `We have ${numDocsString} highly trained optometrists`;
};

const TOKENS = ['siteName', 'city', 'name', 'numDoctors'];

const tooltipStyles = makeStyles({
  tooltip: {
    fontSize: '14px',
  },
});

interface LocationDescriptionProps {
  siteName: string;
  city: string;
  name: string;
  numDoctors: string;
  [key: string]: string | number;
}

const defaultLocationDescription = (data: LocationDescriptionProps): ReactElement => {
  return (
    <>
      {LOCATION_DESCRIPTION.map((seg) =>
        TOKENS.reduce(
          (acc, token) => acc.replace(new RegExp(`{${token}}`, 'gi'), data[token] as string),
          seg
        )
      ).map((seg, index) => (
        <p key={index} className="mt-2">
          {seg}
        </p>
      ))}
    </>
  );
};

export default function DefaultLocationDetailsTemplate<K extends DynamicComponentTypes, CP>({
  preview,
  title,
  siteData,
  currentSlug,
  content,
  marketingMaterials,
  nearbyLocations,
  sharedLocationData,
  locationSysId,
  pageDates,
  reviewAvg,
  reviews,
}: DefaultLocationDetailsTemplateProps<K, CP>): ReactElement {
  const {
    genericServices,
    insuranceProviders,
    insuranceDisclaimer,
    insuranceSlug,
    locationCallouts,
    promo,
    interpreterAvailabilityText,
    banners,
    closedDates,
    scheduleApptCtaText,
  } = sharedLocationData;

  const [activeLocation, setActiveLocation] = useState<Location | null>(null);

  // unique set of services from doctors, locationServices, and genericServices
  const doctorServices = (content.doctors || [])
    .flatMap((doctor) => doctor?.fields?.specialties)
    .filter((item) => item !== undefined);

  const excludeGeneric =
    content.excludeSharedGenericServices !== undefined && content.excludeSharedGenericServices;

  const combined = excludeGeneric
    ? [...doctorServices, ...(content.locationServices || [])]
    : [...doctorServices, ...(content.locationServices || []), ...(genericServices || [])];

  const stringified = combined.map((service) => JSON.stringify(service));

  const services = Array.from(new Set(stringified))
    .map((service) => JSON.parse(service))
    .sort((a: IFragmentService, b: IFragmentService) =>
      a.fields.service.localeCompare(b.fields.service)
    );

  const components = marketingMaterials?.map(
    (comp: PreparedComponentObject<K, CP>, index: number) => {
      const dynamicComp = buildDynamicComponent(comp, siteData.siteName, preview);

      if (dynamicComp === undefined) return;

      return (
        <div className="mx-auto" key={index}>
          {dynamicComp}
        </div>
      );
    }
  );

  const locationBanners = (banners || [])
    .filter(({ fields }) => fields.locations?.includes(locationSysId))
    .map((banner) => banner.fields.bannerText);

  const sharedPromoComponent = promo
    ? buildDynamicComponent<
        typeof promo.sys.contentType.sys.id,
        GenericDef<typeof promo.sys.contentType.sys.id, unknown, typeof promo.fields>,
        typeof promo.fields
      >(
        {
          type: promo.sys.contentType.sys.id,
          fields: promo.fields,
        },
        siteData.siteName
      )
    : null;

  const toolTipClass = tooltipStyles();

  const description = `Looking for a local eye care doctor in ${content.city}? ${siteData.siteName} has an experienced team at our ${content.name} office. Schedule an exam today!`;
  const nextSeoProps = {
    title,
    description,
  };

  let main_header = '';
  let sub_header = '';

  if (content.main_header === '' || content.main_header === undefined) {
    main_header = content.name + ' | ' + content.city + ' Eye Care';
  } else {
    main_header = content.main_header;
  }

  if (content.sub_header === '' || content.sub_header === undefined) {
    sub_header = 'Our ' + content.name + ' Eye Care Location';
  } else {
    sub_header = content.sub_header;
  }

  const nearbyLocationsForMap = [
    ...(nearbyLocations || []).map((location) => {
      return {
        ...location.fields,
        map: location.fields.map,
        name: location.fields.name,
        slug: location.fields.slug,
      };
    }),
    {
      ...content,
      map: { lat: content.map.lat, lon: content.map.lon },
      name: content.name,
      slug: content.slug,
      current: true,
    },
  ];

  const defaultCenter = { lat: content.map.lat, lng: content.map.lon };

  return (
    <>
      <Layout
        siteData={siteData}
        preview={preview}
        nextSeoProps={nextSeoProps}
        currentSlug={`locations/${currentSlug}`}
        warningBanners={locationBanners}
        pageDates={pageDates}
      >
        {/* Structured Data */}
        <LocationData
          location={content}
          siteName={siteData.siteName}
          domain={siteData.domain}
          description={description}
        />

        {reviews && reviewAvg && (
          <SchemaOrg
            json={{
              '@context': 'https://schema.org',
              ...reviewSchema({
                type: 'location',
                id: locationId(siteData.domain),
                outOfFive: reviewAvg.fiveStarAvg,
                count: reviewAvg.count,
                reviews,
              }),
            }}
          />
        )}
        <SharedLocationDataContext.Provider
          value={{ scheduleAppointmentCtaText: scheduleApptCtaText || '' }}
        >
          <ScheduleAppointmentCta
            location={content}
            colorScheme="Primary"
            wrapperClasses="md:hidden p-6"
          />

          {/* Hero section */}
          <div className="flex flex-col md:flex-row lg:py-12 lg:px-16 md:bg-muted">
            {content.officePhoto && (
              <div className="w-2/3 self-center">
                <Image image={content.officePhoto} query="w=680&h=430" classNames="m-auto" />
              </div>
            )}
            <div className="px-4 md:px-10 lg:px-0 lg:ml-20 mt-4 lg:mt-20">
              <h3 className="font-heading text-3xl lg:text-4xl">
                {main_header}

                <div className="flex justify-left">
                  {reviewAvg && (
                    <Score
                      locationId={content.name}
                      reviewCount={reviewAvg.count}
                      reviewOutOfFive={reviewAvg.fiveStarAvg}
                    />
                  )}
                </div>
              </h3>
              <AddressBlock {...content} />
            </div>
          </div>

          {/* Office description */}

          <div className="prose prose-lg max-w-none p-4 lg:px-16 mt-8">
            <h2 className="font-heading text-2xl lg:text-4xl mb-8">{sub_header}</h2>
            {content.description ? (
              <RichText document={content.description} />
            ) : (
              defaultLocationDescription({
                name: content.name,
                city: content.city,
                siteName: siteData.siteName,
                numDoctors: numDocsText((content?.doctors || []).length),
              })
            )}

            <ScheduleAppointmentCta
              location={content}
              colorScheme="Primary3"
              wrapperClasses="mt-6 hidden md:inline-block"
            />
          </div>

          {interpreterAvailabilityText && (
            <RichText
              document={interpreterAvailabilityText}
              classNames="px-4 lg:px-16 mt-6 mb-0 lg:my-12 font-bold"
            />
          )}
          {content.locationDescription && (
            <RichText
              document={content.locationDescription}
              classNames="prose prose-sm lg:prose-lg max-w-none bg-neutral p-4 lg:py-12 lg:px-24 lg:text-center mt-6 lg:my-12 lg:mx-16"
            />
          )}

          {/* Office details map */}
          <OfficeDetails
            siteName={content.site.fields.name}
            officeName={content.name}
            hours={content.hours as OfficeHours}
            hideEyeOnPin={!!sharedLocationData.hideEyeIcon}
            markerIcon={sharedLocationData?.markerPinIcon || 'Eye-V01'}
            closedDates={closedDates}
            hoursNotes={content.hoursNotes}
            backgroundColor="neutral"
            center={{
              lat: content.map.lat,
              lng: content.map.lon,
            }}
            officeInfo={content}
          >
            <ScheduleAppointmentCta
              location={content}
              colorScheme="Primary3"
              classNames="mt-6 hidden lg:inline-block"
            />
          </OfficeDetails>

          {/* Services offered */}
          {services.length > 0 && (
            <Services heading="Services Offered at this Location:" services={services} />
          )}

          {/* accepted insurance */}
          {insuranceProviders && (
            <InsuranceProviders
              insuranceProviders={insuranceProviders.map((item) => item.fields)}
              insuranceDisclaimer={insuranceDisclaimer}
              insuranceSlug={insuranceSlug}
            />
          )}

          {/* Our doctors */}
          {content.doctors && content.doctors.length > 0 && (
            <div className="mt-8 px-4 lg:px-16">
              <h2 className="font-heading text-2xl lg:text-4xl mb-8">Our Eye Doctors</h2>
              <div className="flex flex-wrap">
                {content.doctors.map((doctor, i) => {
                  return (
                    <div className="md:w-doctor w-doctorMobile border-transparent border-8" key={i}>
                      <Image
                        image={doctor.fields.doctorPhoto}
                        query="w=280&h=360"
                        classNames="md:h-doctor h-doctorMobile w-full object-cover"
                      />
                      <div className="mt-2 flex flex-col tezt-sm lg:text-base">
                        {doctorDisplayName(doctor.fields)}
                        <Link
                          slug={`/doctors/${doctor.fields.slug}`}
                          query={{ office: content.slug }}
                        >
                          <a className="text-tertiary-actual">View Doctor Information</a>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* location callouts */}
          {locationCallouts && (
            <div className="md:mt-4 p-4 lg:px-20 md:py-12 md:bg-neutral flex flex-col md:flex-row">
              {locationCallouts.map((callout, i) => (
                <LocationCallout
                  key={i}
                  index={i}
                  office={{ ...content, brand: content.site.fields.name }}
                  {...callout.fields}
                />
              ))}
            </div>
          )}

          {sharedPromoComponent && content.disableSharedPromo !== true && (
            <div className="mt-10">{sharedPromoComponent}</div>
          )}

          {/* marketing materials */}
          {components && <div className="mt-10">{components}</div>}

          {/* testimonials */}
          {reviews && (
            <div className="mb-10 mt-10">
              <Testimonial
                title="Testimonials"
                testimonials={reviews.map(reviewToTestimoial)}
                backgroundColor="neutral"
              />
            </div>
          )}

          {/* nearby locations */}
          {nearbyLocations.length > 0 && (
            <>
              <div className="px-4 lg:px-16 mb-16 mt-10">
                <h2 className="font-heading text-2xl lg:text-4xl py-6 lg:ml-16">
                  Nearby Locations
                </h2>
                <div className="flex flex-col md:flex-row lg:px-16 gap-x-3">
                  {nearbyLocations.map((location, i) => {
                    const bannerText = (banners || [])
                      .filter(({ fields }) => fields.locations?.includes(location.sys.id))
                      .map((banner) => banner.fields.bannerText)
                      .join(' -- ');
                    return (
                      <div
                        key={i}
                        className="flex flex-col justify-between text-center w-full lg:w-1/3 border-transparent lg:border-8 py-6 md:shadow-card"
                      >
                        <div>
                          <div className="flex justify-center items-center">
                            {bannerText && (
                              <Tooltip
                                classes={{ tooltip: toolTipClass.tooltip }}
                                title={bannerText}
                                placement="top-start"
                              >
                                <WarningIcon
                                  className="text-red"
                                  style={{ fontSize: 20, marginRight: '8px' }}
                                />
                              </Tooltip>
                            )}
                            <Link slug={`locations/${location.fields.slug}`}>
                              <a className="block font-heading text-xl underline">
                                {location.fields.name}
                              </a>
                            </Link>
                          </div>
                          <span className="block text-lg mt-2">
                            {location.fields.address1 + ' '}
                            {location.fields.address2 && <>{location.fields.address2 + ' '}</>}
                            {location.fields.address3 && <>{location.fields.address3 + ' '}</>}
                            {location.fields.city +
                              ', ' +
                              location.fields.state +
                              ' ' +
                              location.fields.zipCode}
                          </span>
                          <span className="block text-lg">
                            {formatPhoneNumber(location.fields.phoneNumber)}
                          </span>
                        </div>

                        <ScheduleAppointmentCta
                          location={location.fields}
                          colorScheme="Primary"
                          wrapperClasses="block"
                          classNames="mt-4 block"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Nearby locations map */}

              {/* <Map
                content={content}
                defaultZoom={12}
                nearbyLocations={nearbyLocations}
                hideEyeIcon={!!sharedLocationData.hideEyeIcon}
                markerIcon={sharedLocationData?.markerPinIcon || 'Eye-V01'}
              ></Map> */}

              <div className="lg:mx-32 mb-10" style={{ height: '500px' }}>
                <MapComponent
                  defaultCenter={defaultCenter}
                  iconDetails={{
                    title: 'test',
                    hideEyeOnPin: false,
                    classNames: 'text-primary-actual',
                    focused: true,
                    markerIcon: 'Eye-V01',
                  }}
                  foundLocations={nearbyLocationsForMap}
                  mapOptions={{
                    fullscreenControl: false,
                    zoomControl: true,
                    unBlockOnClick: true,
                  }}
                  showPopUp={true}
                  activeLocation={activeLocation}
                  setActiveLocation={setActiveLocation}
                  renderInfoCard={'InfoCard'}
                />
              </div>
            </>
          )}
        </SharedLocationDataContext.Provider>
      </Layout>
    </>
  );
}

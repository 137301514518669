import Layout from 'components/UI/Layout';
import RichText from 'components/UI/Richtext';

import type { Entry } from 'contentful';

import React, { MutableRefObject, ReactElement, useRef, useEffect, useState } from 'react';
import { BrandedSiteProps, PageDates } from 'lib/routing';
import {
  IFragmentService,
  IMetaLocationsSharedDataFields,
  IPageFields,
  IPageLocationFields,
} from 'types/contentful';
import { ProductVariantFieldsWithBrand } from 'components';
import LocationData from 'components/StructuredData/LocationData';
import SharedLocationDataContext from 'context/SharedLocationDataContext';
import { Review, ReviewAverage } from 'lib/reviewinc';
import { Location } from 'components/Contentful/Map';
import HoursOfOperation from 'components/UI/HoursOfOperation';
import NewMap from 'components/UI/NewMap';
import LocationDetailsHeader from 'components/UI/LocationDetailsHeader';
import ProductCarousel from 'components/Contentful/ProductCarousel';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import NearbyClinicCard from 'components/UI/NearbyClinicCard';
import NewInsuranceProviders from 'components/UI/NewInsuranceProviders';
import DoctorCarousel from 'components/Contentful/DoctorCarousel';
import LocationOffering from 'components/UI/LocationOffering';
import SlimHero from 'components/Contentful/Hero/SlimHero';
import ArticleCarousel from 'components/Contentful/ArticleCarousel';

export type LocationDetailsTemplate2025Props = {
  preview: boolean;
  content: IPageLocationFields;
  template: '2025';
  nearbyLocations: Entry<IPageLocationFields>[];
  sharedLocationData: IMetaLocationsSharedDataFields;
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  locationSysId: string;
  pageDates?: PageDates;
  reviewAvg?: ReviewAverage;
  reviews?: Review[];
  productWithBrand?: ProductVariantFieldsWithBrand[];
  articles?: IPageFields[];
};

export default function LocationDetailsTemplate2025({
  preview,
  title,
  siteData,
  currentSlug,
  content,
  nearbyLocations,
  sharedLocationData,
  locationSysId,
  pageDates,
  productWithBrand,
}: LocationDetailsTemplate2025Props): ReactElement {
  const {
    genericServices,
    insuranceProviders,
    insuranceDisclaimer,
    insuranceSlug,
    meetDoctorsSlug,
    orderContactsSlug,
    articles,
    interpreterAvailabilityText,
    banners,
    scheduleApptCtaText,
  } = sharedLocationData;

  const [activeLocation, setActiveLocation] = useState<Location | null>(null);

  useEffect(() => {
    setActiveLocation(content);
  }, [content]);

  // unique set of services from doctors, locationServices, and genericServices
  const doctorServices = (content.doctors || [])
    .flatMap((doctor) => doctor?.fields?.specialties)
    .filter((item) => item !== undefined);

  const excludeGeneric =
    content.excludeSharedGenericServices !== undefined && content.excludeSharedGenericServices;

  const specialists = doctorServices
    .map((service) => JSON.stringify(service)) // Convert to strings for uniqueness
    .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
    .map((service) => JSON.parse(service)) // Convert back to objects
    .sort((a: IFragmentService, b: IFragmentService) =>
      a.fields.service.localeCompare(b.fields.service)
    );

  let generics: IFragmentService[] = [];

  if (!excludeGeneric) {
    const combinedServices = [...(content.locationServices || []), ...(genericServices || [])];

    generics = Array.from(
      new Set(
        combinedServices
          .filter(
            (service) =>
              !specialists.some(
                (speciality) => speciality.fields.service === service.fields.service
              )
          )
          .map((service) => JSON.stringify(service))
      )
    )
      .map((service) => JSON.parse(service))
      .sort((a: IFragmentService, b: IFragmentService) =>
        a.fields.service.localeCompare(b.fields.service)
      );
  }

  const locationBanners = (banners || [])
    .filter(({ fields }) => fields.locations?.includes(locationSysId))
    .map((banner) => banner.fields.bannerText);

  const description = `Looking for a local eye care doctor in ${content.city}? ${siteData.siteName} has an experienced team at our ${content.name} office. Schedule an exam today!`;
  const nextSeoProps = {
    title,
    description,
  };

  const defaultCenter = { lat: content.map.lat, lng: content.map.lon };
  const mapContainer = useRef() as MutableRefObject<HTMLDivElement>;

  const productCarouselDescription: Document = {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.PARAGRAPH,
        content: [
          {
            data: {},
            marks: [],
            value: 'See yourself in a whole new light-right from home. With our ',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: 'virtual try-on ',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [],
            value: 'tool you can find frames that are perfect for you. ',
            nodeType: 'text',
          },
        ],
        data: {},
      },
    ],
  };

  return (
    <>
      <Layout
        siteData={siteData}
        preview={preview}
        nextSeoProps={nextSeoProps}
        currentSlug={`locations/${currentSlug}`}
        warningBanners={locationBanners}
        pageDates={pageDates}
      >
        {/* Structured Data */}
        <LocationData
          location={content}
          siteName={siteData.siteName}
          domain={siteData.domain}
          description={description}
        />

        <SharedLocationDataContext.Provider
          value={{ scheduleAppointmentCtaText: scheduleApptCtaText || '' }}
        >
          {/* LOCATION DETAILS */}
          <div className="pb-10 lg:pb-0">
            <div className="md:px-16">
              <LocationDetailsHeader location={content} />
              <div className="w-full h-auto flex flex-col lg:flex-row pb-10">
                <div className="w-full lg:w-1/2">
                  {activeLocation ? (
                    <div
                      className="h-76 mx-2 lg:mx-0 px-9 pb-8 lg:pb-0 relative relative"
                      ref={mapContainer}
                    >
                      <NewMap
                        defaultCenter={defaultCenter}
                        mapOptions={{
                          fullscreenControl: false,
                          zoomControl: true,
                          unBlockOnClick: true,
                        }}
                        zoom={10}
                        showPopUp={false}
                        activeLocation={activeLocation}
                        setActiveLocation={setActiveLocation}
                        renderInfoCard={'MapCard'}
                        userLocation={{
                          ...content,
                          map: { lat: content.map.lat, lng: content.map.lon },
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="hidden lg:block">
                    {content.hoursNotes && (
                      <div className="border-secondary py-3 lg:border-t border-b">
                        <p>{content.hoursNotes}</p>
                      </div>
                    )}
                    {interpreterAvailabilityText && (
                      <div className="border-secondary py-3 border-b">
                        <RichText document={interpreterAvailabilityText} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="hidden lg:block w-full lg:w-1/2 bg-muted">
                  <HoursOfOperation locationHours={content.hours} insuranceSlug={insuranceSlug} />
                </div>
              </div>
            </div>

            <div className="w-full lg:hidden text-center">
              <HoursOfOperation locationHours={content.hours} insuranceSlug={insuranceSlug} />
              {content.hoursNotes && (
                <div className="border-secondary py-3 lg:border-t border-b">
                  <p>{content.hoursNotes}</p>
                </div>
              )}
              {interpreterAvailabilityText && (
                <div className="border-secondary py-3 border-b">
                  <RichText document={interpreterAvailabilityText} />
                </div>
              )}
            </div>
          </div>

          {/* Our doctors */}
          {content.doctors?.length && (
            <DoctorCarousel
              title=""
              titleElement={
                <h5 className={`px-10 md:px-24 text-center text-2xl md:text-4xl`}>
                  Your <b>{content.name} </b> Team Of Doctors
                </h5>
              }
              doctors={content.doctors.map((item) => item.fields)}
              officeSlug={content.slug}
            />
          )}

          {/* accepted insurance */}
          {insuranceProviders && (
            <NewInsuranceProviders
              heading={'Insurance Accepted At This Location'}
              insurances={insuranceProviders}
              disclaimer={insuranceDisclaimer}
              cta={
                insuranceSlug
                  ? {
                      title: 'insurance-and-payment',
                      text: 'Insurance & Payment',
                      slug: insuranceSlug,
                      linkToAsset: undefined,
                      colorScheme: 'OutlinedTeal',
                      openInNewTab: false,
                    }
                  : undefined
              }
            />
          )}

          {/* Services offered */}
          {specialists && specialists.length > 0 && (
            <LocationOffering
              id="1"
              services={generics}
              secondRowServices={specialists}
              heading="Eye Care Services"
              secondRowHeading="Specialities At This Location"
            />
          )}

          <SlimHero
            heading="Looking For A Specific Doctor?"
            image="/images/CTA-2.jpg"
            mobileImage="/images/slim-hero-doctors-mobile.jpg"
            body={{
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  content: [
                    {
                      nodeType: 'text',
                      value: `Browse the doctors' page to find eye care professionals near you and learn more about them.`,
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            }}
            cta={
              meetDoctorsSlug
                ? {
                    title: 'meet-our-doctors',
                    text: 'Meet The Doctors',
                    slug: meetDoctorsSlug,
                    linkToAsset: undefined,
                    colorScheme: 'OutlinedTeal',
                    openInNewTab: false,
                  }
                : undefined
            }
          />

          {productWithBrand && productWithBrand.length > 0 && (
            <ProductCarousel
              title="See Yourself In A New Pair of Frames"
              description={productCarouselDescription}
              products={productWithBrand}
              cta={{
                title: 'find',
                text: 'Shop All Frames',
                slug: '/shop/eyeglasses',
                linkToAsset: undefined,
                colorScheme: 'OutlinedTeal',
                openInNewTab: false,
              }}
            />
          )}

          <SlimHero
            heading="Clear Vision With Total Comfort"
            image="/images/CTA-1.jpg"
            mobileImage="/images/slim-hero-contacts-mobile.jpg"
            body={{
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Find your contacts and get fast, free shipping on every order',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            }}
            cta={
              orderContactsSlug
                ? {
                    title: 'order-contacts',
                    text: 'Order Contacts',
                    slug: orderContactsSlug,
                    linkToAsset: undefined,
                    colorScheme: 'OutlinedTeal',
                    openInNewTab: false,
                  }
                : undefined
            }
          />

          {articles ? (
            <ArticleCarousel id="3" heading="Learn More About Your Eye Health" blocks={articles} />
          ) : null}

          {nearbyLocations && nearbyLocations.length ? (
            <NearbyClinicCard locations={nearbyLocations} />
          ) : null}
        </SharedLocationDataContext.Provider>
      </Layout>
    </>
  );
}

import React, { ReactElement, useState, useEffect } from 'react';
import Link from 'components/UI/Link';
import ScheduleAppointmentCta from './ScheduleAppointmentCta';
import { IPageLocationFields } from 'types/contentful';
import NewLocationDetailsCard from './NewLocationDetailsCard';
import AccordianBanner from './AccordianBanner';
import type { Entry } from 'contentful';

interface NearbyClinicCardProps {
  locations: Entry<IPageLocationFields>[];
}

export default function NearbyClinicCard({ locations }: NearbyClinicCardProps): ReactElement {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleAccordion = (): void => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isLargeScreen) {
      setIsOpen(true);
    }
  }, [isLargeScreen]);

  useEffect(() => {
    const updateScreenSize = (): void => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  return (
    <div className="lg:py-10 lg:px-16">
      <AccordianBanner
        onClick={toggleAccordion}
        isOpen={isOpen}
        isLargeScreen={isLargeScreen}
        header={'Nearby Eye Clinics'}
      />

      {isOpen && (
        <div className="flex flex-col lg:flex-row lg:gap-x-8 md:w-4/5 md:mx-auto">
          {locations.slice(0, 3).map((location, index) => (
            <div
              key={index}
              className="flex flex-col bg-transparent lg:bg-muted w-full mt-8 lg:mt-0 px-12 lg:px-2 py-2 lg:py-6 lg:justify-between border-b-2 border-muted"
            >
              <div className="w-full">
                <NewLocationDetailsCard
                  key={index}
                  location={location.fields}
                  className="lg:text-center"
                />
              </div>
              <div className="w-full text-center mt-6 mb-3">
                <ScheduleAppointmentCta
                  location={location.fields}
                  colorScheme="Primary"
                  wrapperClasses="block"
                  classNames="flex md:mx-2 button-text text-primary-actual remove-cta-padding justify-center "
                  text="Schedule An Eye Exam"
                />
                <Link slug={`locations/${location.fields.slug}`}>
                  <a className="block bold text-tertiary-actual mt-6">View Location Details</a>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
